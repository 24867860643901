import { format } from 'date-fns'

export default class Format {

    public static toDate(input: number, dateFormat = 'dd/MM/yyyy') {
        //use format='dddd, Do MMMM, YYYY' for 'Thursday, 6th February, 2020'
        return format(new Date(input), dateFormat);
    }
    public static toTime(input: number) {
        return format(new Date(input), 'hh:mm aaa');
    }
    public static currencyFormat(value, toCurrencyFormat = 'INR', locale = 'en-IN', decimalLimit = 0) {

        return new Intl.NumberFormat(locale, {
            style: 'currency',
            minimumFractionDigits: decimalLimit,
            currency: toCurrencyFormat
        }).format(isNaN(value) ? 0 : value);
    }

    public static formatDistance(value, toUnit = 'km') {

        return (toUnit == 'km' ? `${value}km` : `${(value * 0.621371).toFixed(2)}mi`)

    }

}